import React from 'react';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to "/second" and pass state
        navigate('/repo-ai', { state: {
                lgShow: {
                    show1: true,  // Set the desired show states
                    show2: false,
                    show3: false,
                    show4: false,
                },
            }
        });
    };

    return (
        <div className="main-content">
            <header className="hero heroBanner_qdFl">
                <div className="container">
                    <h1 className="hero_title">Paper Aviation</h1>
                    <p className="hero_subtitle"> Welcome to the world's leading open-source community management team!
                        Through our strategic partnerships,
                        we deliver cutting-edge insights to our clients with unparalleled reporting and metric-based
                        information.</p>
                </div>
            </header>

            <section className="facts">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Metrics</h3>
                            <div>
                                <p>
                                    By tracking pull and push requests, community
                                    leaders can monitor contributions and engagement levels in real time.
                                </p>
                                <p>
                                    This metrics-focused approach helps managers foster collaboration and
                                    support valuable contributors effectively.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Analytics</h3>
                            <div>
                                <p>
                                    Paper Aviation’s analytics tools give community managers detailed insights
                                    into engagement patterns, top contributors, and participation trends.
                                </p>
                                <p>
                                    This data-driven approach allows managers to make strategic decisions,
                                    identify growth opportunities, and create a more engaged and thriving community.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Reporting</h3>
                            <div>
                                <p>
                                    With Paper Aviation’s reporting tools, managers get clear, organized views of
                                    community activity, including contributions and engagement trends.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row main_video_container">
                        <div className="col-12">
                            <h3>Repo AI</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div>
                                <p>
                                    Repo AI integrates seamlessly with GitHub, enabling developers to analyze
                                    repositories directly from their version control system.
                                    With this integration, developers can access automated code reviews, track technical
                                    debt, and receive real-time suggestions
                                    for improving code quality without leaving their development environment.
                                </p>
                                <p>
                                    Repo AI also assists in maintaining coding standards
                                    and security best practices by flagging issues proactively. Through integrations
                                    with CI/CD pipelines, developers can ensure
                                    that every new commit is analyzed for potential improvements, making code
                                    maintenance and enhancement a continuous, streamlined process.
                                </p>
                                <button type="button" className="btn btn-secondary btn-lg" onClick={handleClick}>Try It Out</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <video autoPlay loop muted>
                                <source src="Paper_Aviation_and_Reppo_Demo_Smol.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Main;
