// src/App.tsx
import React, { useEffect, useState } from 'react';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RepoAi from "./components/RepoAi/RepoAi";
import Home from "./components/Home";


const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/repo-ai" element={<RepoAi />} />
            </Routes>
        </Router>
    );
};

export default App;
