// src/components/Main.tsx
import React, {useEffect, useState} from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import Main from "./Main";
import RepoAi from "./RepoAi/RepoAi";

const Home: React.FC = () => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Fetch data from backend
        fetch('http://localhost:5001/api')
            .then((response) => response.json())
            .then((data) => {
                setMessage(data.message);
            })
            .catch((error) => console.error('Error:', error));
    }, []);

    return (
        <div className="app">
            <NavBar/>
            <Main />
            <Footer/>
        </div>
    );
};

export default Home;
