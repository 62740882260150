// src/components/Footer.tsx
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer: React.FC = () => {
    return (
        <footer className="footer1 page-footer footer bg-dark text-white py-4 w-100">
            <Container>
                <Row>
                    <Col md={6}>
                        <h5>About Us</h5>
                        <p>Providing exceptional service and support.</p>
                    </Col>
                    <Col md={6} className="text-md-end">
                        <h5>Contact Info</h5>
                        <p>Email: info@mywebsite.com</p>
                    </Col>
                </Row>
                <div className="text-center mt-3">
                    <small>&copy; {new Date().getFullYear()} MyWebsite. All rights reserved.</small>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
