// src/components/Main.tsx
import React, {useEffect, useState} from 'react';
import NavBar from "../NavBar";
import Footer from "../Footer";
import {Modal} from "react-bootstrap";

import { useLocation } from 'react-router-dom';


const RepoAi: React.FC = () => {

    const location = useLocation();
    const passedState = location.state?.lgShow;

    const [lgShow, setLgShow] = useState({
        show1: false,
        show2: false,
        show3: false,
        show4: false,
    });

    const handleButtonClick1 = () => {
        setLgShow({
            show1: true,
            show2: false,
            show3: false,
            show4: false,
        })
    };

    const handleButtonClick2 = () => {
        setLgShow({
            show1: false,
            show2: true,
            show3: false,
            show4: false,
        })
    };

    const handleButtonClick3 = () => {
        setLgShow({
            show1: false,
            show2: false,
            show3: true,
            show4: false,
        })
    };

    const handleButtonClick4 = () => {
        setLgShow({
            show1: false,
            show2: false,
            show3: false,
            show4: true,
        })
    };

    const closeModals = () => {
        setLgShow({
            show1: false,
            show2: false,
            show3: false,
            show4: false,
        })
    };

    // Update state on mount if passedState exists
    useEffect(() => {
        if (passedState) {
            setLgShow(passedState);
        }
    }, [passedState]);


    useEffect(() => {
        // Dynamically load the Stripe.js script
        const script = document.createElement("script");
        script.src = "https://js.stripe.com/v3/buy-button.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Clean up the script on component unmount
            document.body.removeChild(script);
        };
    }, []);


    return (
        <div className="app">
            {/*------------- Nav Bar -------------*/}
            <NavBar/>

            {/*------------- Body -------------*/}

            <header className="hero heroBanner_qdFl">
                <div className="container">
                    <h1 className="hero_title">Repo AI</h1>
                </div>
            </header>


            <section className="facts">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Effective Communication</h3>
                            <div>
                                <p>
                                    Repo AI enhances developer communication by delivering real-time,
                                    AI-driven code reviews and actionable feedback within GitHub.
                                    This enables teams to address issues collaboratively and align on best practices for
                                    faster,
                                    informed decision-making.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Evaluating Leads</h3>
                            <div>
                                <p>

                                    Repo AI streamlines lead evaluation by providing real-time,
                                    AI-driven insights and scoring directly within the platform.
                                    This enables teams to prioritize high-quality leads effectively,
                                    enhancing collaboration and decision-making.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 facts_item">
                            <h3>Potential Leaders</h3>
                            <div>
                                <p>
                                    Repo AI aids in identifying potential leaders by offering AI-driven insights into
                                    code contributions and collaboration patterns.
                                    This helps teams recognize and nurture leadership qualities, fostering a culture of
                                    growth and effective mentorship.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row main_video_container">
                        <div className="col-12">
                            <h3>Repo AI</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div>
                                <p>
                                Repo AI integrates seamlessly with GitHub, enabling developers to analyze
                                    repositories directly from their version control system.
                                    With this integration, developers can access automated code reviews, track technical
                                    debt, and receive real-time suggestions
                                    for improving code quality without leaving their development environment.
                                </p>
                                <p>
                                    Repo AI also assists in maintaining coding standards
                                    and security best practices by flagging issues proactively. Through integrations
                                    with CI/CD pipelines, developers can ensure
                                    that every new commit is analyzed for potential improvements, making code
                                    maintenance and enhancement a continuous, streamlined process.
                                </p>
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-lg"
                                    onClick={() => handleButtonClick1()}>Try It Out</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <video autoPlay loop muted>
                                <source src="Paper_Aviation_and_Reppo_Demo_Smol.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                size="lg"
                show={lgShow.show1}
                onHide={closeModals}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Onboard Data Into Vector Model
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <h5>Choose from Data Lake.</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col-input d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col-input d-flex">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder=""
                                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="bi bi-search"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col-input d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col-input d-flex">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder=""
                                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="bi bi-search"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col-input d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col-input d-flex">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder=""
                                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="bi bi-search"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col-input d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col-input d-flex">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder=""
                                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="bi bi-search"/>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="repo_modal_upload_container">
                            <div className="container">
                                <div className="row">
                                    <div className="col d-flex justify-content-center">
                                        <i className="bi bi-upload upload_icon"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col d-flex justify-content-center">
                                        Upload file here or select file.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder=""
                                   aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                            <span className="input-group-text" id="basic-addon2">
                                    <i className="bi bi-upload"/>
                                </span>
                        </div>

                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            onClick={() => handleButtonClick2()}

                        >Next
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={lgShow.show2}
                onHide={closeModals}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Choose Your Model
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <h5>Select the model you would like to run.</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_model">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-airplane"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_model">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-airplane"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_model">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-airplane"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_model">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-airplane"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_model">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-airplane"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            onClick={() => handleButtonClick3()}
                        >Next
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark float-start"
                            onClick={() => handleButtonClick1()}
                        ><i className="bi bi-arrow-left-short"></i> Back
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={lgShow.show3}
                onHide={closeModals}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Create Prompts
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <h5>Select which prompt you would like to use.</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_prompt">
                                            <div
                                                className="col col-11 model-item-col-6 d-flex align-items-center">Effective
                                                Communication
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_prompt">
                                            <div
                                                className="col col-11 model-item-col-6 d-flex align-items-center">Evaluating
                                                Leads
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-1 model-1-col d-flex">
                                        <input className="form-check-input repo_radio" type="radio" value="Male"
                                               name="gender"/>
                                    </div>
                                    <div className="col col-11 model-11-col">
                                        <div className="row repo_prompt">
                                            <div
                                                className="col col-11 model-item-col-6 d-flex align-items-center">Potential
                                                Leaders
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            type="button"
                            className="btn btn-primary float-end"
                            onClick={() => handleButtonClick4()}
                        >Next
                        </button>
                        <button
                            type="button"
                            className="btn btn-dark float-start"
                            onClick={() => handleButtonClick2()}
                        ><i className="bi bi-arrow-left-short"></i> Back
                        </button>
                    </form>
                </Modal.Body>
            </Modal>


            <Modal
                size="lg"
                show={lgShow.show4}
                onHide={closeModals}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Checkout
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <h4>Review selected prompts.</h4>
                        <h5>Data Lake Selected Selected</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-12 model-11-col">
                                        <div className="row repo_model_checkout">
                                            <div className="col col-11 model-item-col-6 d-flex align-items-center">
                                                Databricks Lakehouse Platform
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5>Model Selected</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col col-12 model-11-col">
                                        <div className="row repo_model_checkout">
                                            <div
                                                className="col col-11 model-item-col-6 d-flex align-items-center">Llama
                                                3.2 7B
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5>Prompt Selected</h5>
                        <div className="repo_modal_model_container">
                            <div className="container model_container">
                                <div className="row">
                                    <div className="col">
                                        <div className="row repo_prompt_checkout">
                                            <div
                                                className="col col-11 model-item-col-6 d-flex align-items-center">Effective
                                                Communication
                                            </div>
                                            <div className="col col-1 model-item-col-1 d-flex align-items-center"><i
                                                className="bi bi-check2-square"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{margin: "20px", textAlign: "center"}}>
                            <stripe-buy-button
                                buy-button-id="buy_btn_1QK7P0IKs74FE49Jp4nmdJYh"
                                publishable-key="pk_live_51QJeYMIKs74FE49JRNpPaQH8mN3IqGjQQlTbJij8VnmA8EzqlJEtaZY6vv8XWEpK9KituUZOn9Zw9ZyYYVhNgGVA005JpaFYa1"
                            ></stripe-buy-button>
                        </div>
                        <button
                            type="button"
                            className="btn btn-dark float-start"
                            onClick={() => handleButtonClick3()}
                        ><i className="bi bi-arrow-left-short"></i> Back
                        </button>
                    </form>
                </Modal.Body>
            </Modal>

            {/*------------- Footer -------------*/}
            <Footer/>
        </div>

    );
};

export default RepoAi;
