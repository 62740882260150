import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

const NavBar: React.FC = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Container fluid>
                <Navbar.Brand href="/">Paper Aviation</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarNav" />
                <Navbar.Collapse id="navbarNav">
                    <Nav className="me-auto">
                        <Nav.Item>
                            <Nav.Link href="/repo-ai">Repo AI</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#">About Us</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <span className="navbar-text">
                        <span className="p-2">Github</span>
                        <i className="bi bi-github"></i>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
